const CustomerService = {

    url: `${process.env.VUE_APP_API_URL}customers`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (data) => {
        try {

            const response = await fetch(`${CustomerService.url}`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getSuppliers: async (data) => {
        try {
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/get-suppliers`, {
                method: 'GET',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    saveQuizAnswers: async (data) => {
        try {
            const response = await fetch(`${CustomerService.url}/save-quiz-answers`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },
    createBusinessInfo: async (data) => {
        try {
            
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/create-business-info`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    handleShowMetaMapButton: async(field, value) => {
        try {
            
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/handle-show-metamap-button`, {
                method: 'POST',
                body: JSON.stringify({
                    field: field,
                    value: value
                }),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getReferralsBySupplier: async (data) => {
        try {
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/get-referrals-by-supplier`, {
                method: 'GET',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getByUser: async (id) => {
        try {
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/get-by-user/${id}`, {
                method: 'GET',
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    searchCustomer: async (val) => {
        try {
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/search/${val}`, {
                method: 'GET',
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    creditExtension: async () => {
        try {
            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerService.url}/credit-extension`, {
                method: 'POST',
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    createStep1: async (data) => {
        try {

            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            
            const response = await fetch(`${CustomerService.url}/create-step-1`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    createBuyerCustomer: async (data) => {
        try {

            const headers = CustomerService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            
            const response = await fetch(`${CustomerService.url}/create-buyer-customer`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: CustomerService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getAccountStatusByToken: async (token) => {
        try {
          const response = await fetch(`${CustomerService.url}/account-status/${token}`, {
            method: "GET",
            headers: CustomerService.headers,
          });
    
          return response;
        } catch (e) {
          return false;
        }
    },

    exportAccountStatus: async (token) => {
        try {
          const response = await fetch(`${CustomerService.url}/export-account-status/${token}`, {
            method: "GET",
            headers: CustomerService.headers,
          });
    
          return response;
        } catch (e) {
          return false;
        }
    },
}
export default CustomerService