<template>
    <v-card 
      class="elevation-2 mb-6" 
      style="border-right: 4px solid rgb(128, 0, 128, 0.85);"
    >
      <!-- Encabezado del proyecto -->
      <!-- <v-card-title> -->
        <div style="padding: 2em;">
          <h2 style="color: #732C9C;">{{ project.name }}</h2>
          <div class="d-flex justify-space-between mt-2">
            <div>Consumido</div>
            <h3>{{ project.total_amount | currency }}</h3>
          </div>
        </div>
      <!-- </v-card-title> -->
    </v-card>
  </template>
  
<script>
  export default {
    name: "ProjectCard",
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatAmount(value) {
            const amount = parseFloat(value);
            return isNaN(amount) ? "0.00" : amount.toFixed(2);
        },
    },
    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
  };
</script>
  

<style scoped>
  
    .v-card-title {
        font-weight: bold;
        font-size: 1.2rem;
    }
  
    .v-chip {
        border-radius: 20px;
        font-size: 1rem;
        padding: 8px;
    }
  
    .v-card-text {
        background-color: #f8f9fa; /* Fondo claro para contraste */
    }
</style>
  