const SupplierService = {

    url: `${process.env.VUE_APP_API_URL}suppliers`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

    },

    getCustomers: async (data) => {
        try {
            const headers = SupplierService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${SupplierService.url}/get-customers`, {
                method: 'GET',
                body: JSON.stringify(data),
                headers: SupplierService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getBusinessInformation: async (id = null) => {
        try {
            const headers = SupplierService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${SupplierService.url}/get-business-information${ id ? "?id=" + id : "" }`, {
                method: 'GET',
                headers: SupplierService.headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getSuppliersToShow: async () => {
        try {
            const headers = SupplierService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${SupplierService.url}/get-suppliers-to-show`, {
                method: 'GET',
                headers: SupplierService.headers
            })
            
            return response
        } catch (e) {
            return [];
        }
    },
    getBankAccountsBySupplier: async (supplier_id) => {
        try {
            const headers = SupplierService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${SupplierService.url}/get-bank-accounts/${supplier_id}`, {
                method: 'GET',
                headers: SupplierService.headers
            })
            
            return response
        } catch (e) {
            return [];
        }
    },

    getAccountStatus: async () => {
        try {
          const headers = SupplierService.headers;
          headers["Authorization"] = "Bearer " + localStorage.getItem("token");
      
          const response = await fetch(`${SupplierService.url}/account-status`, {
            method: "GET",
            headers: headers,
          });
      
          return response;
        } catch (e) {
          return false;
        }
    },

    exportAccountStatus: async () => {
        try {
          const headers = SupplierService.headers;
          headers["Authorization"] = "Bearer " + localStorage.getItem("token");
      
          const response = await fetch(`${SupplierService.url}/export-account-status`, {
            method: "GET",
            headers: headers,
          });
      
          return response;
        } catch (e) {
          return false;
        }
    }
}

export default SupplierService