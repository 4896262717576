const ProjectService = {
  async getProjects() {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    };
    return await fetch(`${process.env.VUE_APP_API_URL}projects`, { headers });
  },

  async createProject(data) {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };
    return await fetch(`${process.env.VUE_APP_API_URL}projects`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    });
  },

  async assignProjectToToken(projectId) {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };
    return await fetch(`${process.env.VUE_APP_API_URL}projects/assign`, {
      method: 'POST',
      body: JSON.stringify({ project_id: projectId }),
      headers,
    });
  },

  async getProjectsWithTotals() {
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };

    const response = await fetch(`${process.env.VUE_APP_API_URL}projects/projects-with-totals`, {
      method: 'GET',
      headers
    });
    return response;
  },
};

export default ProjectService;
