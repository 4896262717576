<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    class="elevation-1 sticky-table" 
    :loading="loading"
    loading-text="Cargando..."
    :options.sync="options" 
    @update:options="updatePaymentsOrderBy"
    :server-items-length="100"
  >

    <template v-slot:[`item.id`]="{ item }">
      <div class="pt-4 pb-4" v-if="$UserManager.getMainRole() == 'supplier'">
          <v-row>
            <div >
              <b>{{ item.id }}</b> - {{ item.customer.business_name }}
            </div>
          </v-row>
      </div>
      <div v-else>
        {{ item.id }}
      </div>
    </template>
    <template v-slot:[`item.status.name`]="{ item }">
        <v-chip
          class="mt-1 mb-1 font-weight-bold"
          :color="getColorByCode(convertCode(item.status.name))"
          text-color="white"
          small
        >
         {{ translate_code(`${convertCode(item.status.name)}_singular`) }}
        </v-chip>
    </template>

    <!-- Columna del Nombre del Proyecto -->
    <template v-slot:[`item.project_name`]="{ item }">
      <div>
        <v-btn
          v-if="!item.project_name"
          color="purple"
          @click="$emit('assign-project', item)"
          class="white--text"
          outlined
          block
          x-small
        >
          Asignar Proyecto
        </v-btn>
        <div v-else class="d-flex align-center justify-space-between">
          <span>{{ item.project_name }}</span>
          <v-icon 
            @click="$emit('assign-project', item)" 
            small 
            color="purple"
          >mdi-pencil-box</v-icon>
        </div>
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item, index }">
      <MenuActionPayments 
        :item="item" 
        :index="index" 
        :page="page"
        @handleAction="handleAction"
        />
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      <b>{{ item.amount | currency }}</b>
    </template>
    <template v-slot:[`item.reserved_credit_line_id`]="{ item }">
      {{ item.reserved_credit_line_id ?? "No" }}
    </template>

    <template v-slot:[`item.payment_behavior`]="{ item }">
      <div  v-if="showBehavior(item.payment_behavior)">
        <v-chip 
          small
          class="light"

        >
          <b>{{ getBehavior(item.payment_behavior) | capitalizeFirstLetter }}</b>
        </v-chip>
      </div>
    </template>
    <template v-slot:[`item.extended_term`]="{ item }">
      <div>
        <v-chip 
          small
          class="light"
        >
          <b>{{ getExtendedTermText(item.extended_term) }}</b>
        </v-chip>
      </div>
    </template>
    
     <!-- <template v-slot:[`item.term`]="{ item }">
      {{ item.term + " " + (item.term == 1 ? "día" : "días")}}
    </template> -->
    <template v-slot:[`item.invoice_url`]="{ item }">
      <v-chip
        :class="`${item.invoice_url ? 'green' : 'red'} font-weight-bold`"
        text-color="white"
        small
      >
        {{ item.invoice_url ? "Factura cargada" : "Factura no cargada" }}
      </v-chip>
    </template>

    <!-- <template v-slot:[`item.project`]="{ item }">
      <div>
        <v-btn
          color="purple"
          @click="$emit('assign-project', item)"
          small
          class="white--text"
          outlined
          block
        >
          {{ item.project_name ? 'Editar Proyecto' : 'Asignar Proyecto' }}
        </v-btn>
      </div>
    </template> -->
  </v-data-table>
</template>

<script>
import translate_code from "../helpers/translate_code"
import MenuActionPayments from "@/components/MenuActionPayments.vue"

export default {
    props: ["headers", "items", "loading", "code", "page"],
    
    components: {
      MenuActionPayments
    },

    data() {
      return {
        options: {
          sortBy: [],
          sortDesc: []
        },
        selectedTransaction: null,
        showProjectDialog: false,
      }
    },

    methods: {

      updatePaymentsOrderBy() {
          // console.log({options: this.options})
          this.$emit("updatePaymentsOrderBy", this.options)
      },

      handleAction(item, code) {
        this.$emit("handleAction", item, code)
      },
      
      translate_code (key) {
        return translate_code(key)
      },

      convertCode (key) {
        const role = this.$UserManager.getMainRole()

        if (role == "supplier") {
          switch (key) {
            case "paid_to_mangxo":
            case "payment_in_financing":
              key = "paid"
            break
          }
        }

        return key
      },

      getColorByCode (status_code) {
        let color = "light"

        switch(status_code) {
          case "pending":
          case "payment_in_financing":
            color = "warning"
          break
          case "expired":
          case "non_payment":
          case "not_reported":
          case "paid_by_mangxo":
            color = "red"
          break
          case "paid":
          case "paid_to_mangxo":
            color = "success"
          break 
          case "to_confirm":
          // case "paid_by_mangxo":
            color = "primary"
          break
        }

        return color
      },

      openProjectAssignmentDialog(item) {
        this.selectedTransaction = item;
        console.log('ID de Transacción:', item.id);
        this.showProjectDialog = true;
      },

      handleProjectAssignment(project) {
        if (this.selectedTransaction) {
          this.selectedTransaction.project = project;
          this.showProjectDialog = false;
          localStorage.setItem("selectedProject", JSON.stringify(project));
        }
      },
      
      getBehavior (text) {
        return (text == "VIGENTE" || text == "SIN PAGO") ? "-" :  text  
      },

      showBehavior (text) {
        return (text == "VIGENTE" || text == "SIN PAGO") ? false :  true 
      },

      getExtendedTermText(term) {
        let text = "Extendido " + term 
        
        if (term == 0) {
          return "Sin extensión"
        }

        text += term > 1 ? "días" : 'día'
        return text
      }
    },

    filters: {
      currency(value) {
          
          var formatter = new Intl.NumberFormat('es-MX', {
              style: 'currency',
              currency: 'MXN'
          });
          
          return formatter.format(value);
      },

      capitalizeFirstLetter(value) {
        if (!value) return '';
        value = value.toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    }
}
</script>


<style>
  .sticky-table th:first-child, .sticky-table td:first-child {
    position: sticky;
    left: 0;
    background: white; 
    z-index: 2;
  }
</style>