<template>
  <v-dialog v-model="dialog" persistent max-width="500" class="custom-dialog">
    <v-card class="custom-card" style="height: 100%;">
      <v-card-title class="justify-space-between mb-9">
        <span class="text-h6">Asignar compra a proyecto</span>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="custom-card-text mt-9">
          <v-col cols="12 ">
            <v-select
              v-model="selectedProject"
              :items="projects"
              item-text="name"
              item-value="id"
              label="Seleccionar proyecto"
              outlined
              dense
              :disabled="projects.length === 0 || loadingAssignProject"
              return-object
              >
            </v-select>
            <div style="color: red;">{{ errorMsg }}</div>
          </v-col>
          <div>
            <v-col cols="12">
              <v-btn 
                class="white--text" 
                color="#732C9C"
                @click="openNewProjectForm" 
                :loading="loadingCreateProject"
                small
                block
              >
                + Crear nuevo proyecto
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn 
                outlined
                color="grey" 
                @click="closeDialog"
                small
                class="mr-2"
              >
                Cancelar
              </v-btn>
              <v-btn 
                outlined
                color="purple"  
                @click="confirmProject" 
                :loading="loadingAssignProject"
                small
              >
              Confirmar
              </v-btn>
            </v-col>
          </div>
      </v-card-text>
    </v-card>
  
    <!-- Modal para crear un nuevo proyecto -->
    <v-dialog v-model="newProjectDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="justify-space-between">
          <span class="text-h6">Nuevo Proyecto</span>
          <!-- Botón de cierre en el modal del nuevo proyecto -->
          <v-btn icon @click="closeNewProjectDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-text-field
            v-model="newProjectName"
            label="Nombre del proyecto"
            outlined
          ></v-text-field>
          <div style="color: red;">{{ errorMsgNewProject }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn 
            color="grey"
            @click="closeNewProjectDialog"
            outlined
            small
          >
            Cancelar
          </v-btn>
          <v-btn 
            color="purple"
            @click="saveNewProject" 
            :loading="loadingCreateProject" 
            :disabled="loadingCreateProject"
            outlined
            small
            >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
  
<script>
  import ProjectService from '../services/ProjectService';
  // import CustomerTokenService from '@/services/customerTokenService';
  import ProjectActivitiesService from '../services/projectActivitiesService'
  
  export default {
    name: 'DialogProjectAssignment',
    props: {
      value: Boolean,
      transactionId: {
          type: Number,
          required: false
      }
    },
    data() {
      return {
        dialog: this.value,
        newProjectDialog: false,
        newProjectName: '',
        selectedProject: null,
        projects: [],
        loadingCreateProject: false,
        loadingAssignProject: false,
        errorMsg: null,
        errorMsgNewProject: null
      };
    },
    watch: {
      async value(val) {
        this.dialog = val;
        if (val) this.fetchProjects();
        // if (val) this.fetchToken(); 
        if (val) { await ProjectActivitiesService.create({event: "click_to_assign_" + this.$route.name + "_view"}) }
      },
      dialog(val) {
        this.$emit('input', val);
      },

      newProjectDialog() {
        this.errorMsg = null
      }
    },
    methods: {
      async fetchProjects() {
        try {
          const response = await ProjectService.getProjects();
          this.projects = await response.json();
        } catch (error) {
          // console.error('Error al obtener proyectos:', error);
        }
      },

      async openNewProjectForm() {
        this.newProjectDialog = true;
        await ProjectActivitiesService.create({event: "click_to_create_" + this.$route.name + "_view"})
      },

      async confirmProject() {
        // Verifica si el proyecto y el token existen antes de continuar
        if (!this.selectedProject) {
          this.errorMsg = "Debe seleccionar o crear un proyecto"
          // console.error('No hay proyecto seleccionado');
          return;
        }

        if (this.$route.name != "token") {
          this.$emit('confirm', this.selectedProject);
          this.closeDialog()
        }

        // console.log(this.response, "response");

        this.loadingAssignProject = true;

        try {
          const result = await ProjectService.assignProjectToToken(this.selectedProject.id);
          const data = await result.json();
          
          if (data.success) {
        
            if (data.success) {
              this.$emit('confirm', this.selectedProject);
              this.closeDialog()
            } else {
              this.errorMsg = "Ocurrio un error"
            }
          } else {
            this.errorMsg = "Ocurrio un error"
          }
        } catch (error) {
          // this.errorMsg = "Ocurrio un error"
        } finally {
          this.loadingAssignProject = false; // Desactiva el loading
        }
      },

      // async fetchToken() {
      //   try {
      //     const response = await CustomerTokenService.getToken();
      //     const result = await response.json();
      
      //     if (result.token) {
      //       this.response = result;
      //     } else {
      //       // console.error('No se obtuvo un token válido');
      //     }
      //   } catch (error) {
      //     // console.error('Error al obtener el token:', error);
      //   }
      // },

      closeDialog() {
        this.dialog = false;
        this.selectedProject = null
        this.newProjectName = ''
        this.errorMsg = null
      },

      async saveNewProject() {
        if (!this.newProjectName) {
          this.errorMsgNewProject = "Ingrese el nombre del proyecto"
          return;
        } 
        this.loadingCreateProject = true; // Activa el indicador de carga

        try {
          // console.log('Intentando crear un nuevo proyecto con nombre:', this.newProjectName);

          const response = await ProjectService.createProject({ name: this.newProjectName });
          const newProject = await response.json(); 

          if (this.$route.name != "token") {
              this.$emit("confirm", newProject.project);
              this.newProjectDialog = false;
              this.dialog = false;
              return
          }

          if (newProject && newProject.project && newProject.project.id) {
            // console.log("Proyecto creado:", newProject);

            // Asignar el proyecto automáticamente
            const assignResponse = await ProjectService.assignProjectToToken(
              newProject.project.id
            );
            const assignResult = await assignResponse.json();

            if (assignResponse.ok && assignResult.success) {
              console.log("Proyecto asignado automáticamente:", assignResult);

              // Actualizar el `selectedProject` en la vista principal
              this.$emit("confirm", newProject.project); // Emite el nuevo proyecto al padre

              // Cerrar los modales
              this.newProjectDialog = false;
              this.dialog = false;
            } else {
              this.errorMsgNewProject = "Ocurrio un error al crear el proyecto"
              // console.error("Error al asignar el proyecto:", assignResult.message);
            }
          }
        } catch (error) {
          this.errorMsgNewProject = "Ocurrio un error al crear el proyecto"
          // console.error("Error al crear y asignar proyecto:", error);
        } finally {
          this.loadingCreateProject = false; // Desactiva el indicador de carga
        }
      },

      closeNewProjectDialog() {
          this.newProjectDialog = false;
          this.errorMsgNewProject = null
          this.newProjectName = null
      },
    }
  };
</script>

<!-- <style scoped>
  .custom-dialog {
    max-height: 80vh;
  }

  .custom-card {
    display: flex;
    flex-direction: column;
    height: 35vh;
  }

  .custom-card-text {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>

   -->