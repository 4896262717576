<template>
  <v-main>
    <v-container class="py-8 px-6">
      <v-row>
        <v-col cols="12">
          <v-alert
            outlined
            color="#732C9C"
            type="info"
          >
            Aumenta tu linéa de crédito <b>asignando</b> tus <b>compras</b> a <b>proyectos.</b>
          </v-alert>
        </v-col>
      </v-row>
      <div class="text-h4 font-weight-bold pb-6 pt-6" style="color: #732C9C;">Mis proyectos</div>
      <v-row v-if="projects.length > 0">
        <!-- Iterar sobre proyectos y usar el componente ProjectCard -->
        <v-col cols="12" md="6" lg="4" v-for="project in projects" :key="project.id">
          <ProjectCard :project="project" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          No tienes proyectos, vinculalos al realizar una nueva compra o desde el <a @click="$router.push({ name: 'payments'})" color="732C9C">listado de tus compras.</a>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ProjectService from "@/services/ProjectService";
import ProjectCard from "@/components/ProjectCard.vue";

export default {
  name: "ProjectsView",
  components: {
    ProjectCard
  },
  data() {
    return {
      projects: [] // Lista de proyectos con sus pagos
    };
  },
  async created() {
    await this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await ProjectService.getProjectsWithTotals();
        if (response.ok) {
          const projects = await response.json();
          console.log("Proyectos desde el backend:", projects);
          this.projects = projects;
        } else {
          console.error("Error al obtener proyectos:", response.status);
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    }
  }
};
</script>
