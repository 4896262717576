const headers = {
    supplier: {
        payments_base: [
            { text: "ID Transacción", value: "id"}, 
            { text: "Cliente", value: "customer.business_name"}, 
            { text: "Monto", value: "amount"}, 
            { text: "Fecha de Venta", value: "created_at"}, 
            { text: "Fecha de pago", value: "payment_date"}, 
            { text: "Fecha de vencimiento", value: "expiration_date"}, 
            { text: 'Acciones', value: 'actions', sortable: false },
        ],

        payments_all: [
            { text: "ID - Cliente", value: "id", sortable: false, width: "400px"}, 
            { text: "Estado", value: "status.name", sortable: false}, 
            { text: "Factura", value: "invoice_url", sortable: false}, 
            { text: "Monto", value: "amount", sortable: false}, 
            { text: "Plazo", value: "term", sortable: false}, 
            { text: "Vencimiento", value: "expiration_date", width: "120px"}, 
            { text: "Venta", value: "created_at"}, 
            { text: "Pago", value: "payment_date"}, 
            { text: "Referencia", value: "reference", sortable: false}, 
            { text: "Línea reservada", value: "reserved_credit_line_id", sortable: false}, 
            // { text: "Factura", value: "invoice_url"}, 
            { text: "Extensión", value: "extended_term", sortable: false, width: "100px" },
            { text: "Comportamiento", value: "payment_behavior", sortable: false, width: "150px" },
            { text: 'Acciones', value: 'actions', sortable: false },
        ]
    },
    customer: {
        payments_base: [
            { text: "ID Transacción", value: "id"}, 
            { text: "Proveedor", value: "supplier.business_name"}, 
            { text: "Monto", value: "amount"}, 
            { text: "Fecha de compra", value: "created_at"}, 
            { text: "Fecha de pago", value: "payment_date"}, 
            { text: "Fecha de vencimiento", value: "expiration_date"}, 
            { text: 'Acciones', value: 'actions', sortable: false },
        ],

        payments_all: [
            { text: "ID", value: "id",  sortable: false}, 
            { text: "Proveedor", value: "supplier.business_name",  sortable: false}, 
            { text: "Monto", value: "amount",  sortable: false}, 
            // { text: "Plazo", value: "term"}, 
            { text: "Estado", value: "status.name",  sortable: false}, 
            { text: 'Proyecto', value: 'project_name',  width: "220px" },
            { text: "Fecha de Venta", value: "created_at",  width: "150px"}, 
            { text: "Fecha de pago", value: "payment_date",  width: "150px"}, 
            { text: "Vencimiento", value: "expiration_date",  width: "150px"}, 
            { text: 'Acciones', value: 'actions', sortable: false },
            // { text: 'Proyectos', value: 'project', sortable: false},
        ]
    }
}

const headersByRole = {
    "admin-supplier": {
        payments_base: headers.supplier.payments_base,
        payments_all: headers.supplier.payments_all
    },
    "vendor-supplier": {
        payments_base: headers.supplier.payments_base,
        payments_all: headers.supplier.payments_all
    },
    "admin-customer": {
        payments_base: headers.customer.payments_base,
        payments_all: headers.customer.payments_all
    },
    "customer": {
        payments_base: headers.customer.payments_base,
        payments_all: headers.customer.payments_all
    },   
}

export default headersByRole